import { Conditional, ContainerBody, WhatsappBtn } from '@/components';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import PulseStore from '@store/PulseStore';
import { useMobxStores } from '@/store';
import {
  ProductDetails,
  BannerSection,
  BannerSectionEndurance,
  GalleryV1,
  Footer,
  DealSection,
  HeroDesktop2,
  VersionsSectionV3,
} from '@/sections';
import { MenuLabels } from '@/models';
import { Context } from '@/context';
import productDetails, { allSlides } from '@/data/product-details.data';
import { dataVersions } from '@/data/versions.data';
import MainStories from '@/sections/stories-section';
import Banner125Anos from '@/sections/banner-125';

const Home: FC<{ navRef: MenuLabels | undefined; slugSuffix?: string }> = ({
  navRef,
  slugSuffix,
}) => {
  const { state, dispatch } = useContext(Context);
  const store: PulseStore = useMobxStores();
  const timeout = useRef<NodeJS.Timeout | undefined>();
  const [loaded, setLoaded] = useState(false);
  const [loadedSlugSuffix, setLoadedSlugSuffix] = useState(false);
  const handleResize = () => {
    store.setPageX(window?.innerWidth);

    dispatch({
      type: 'SET_PAGE',
      payload: { pageX: window?.innerWidth },
    });

    dispatch({
      type: 'SET_IS_DESKTOP',
      payload: { isDesktop: window?.innerWidth > 1024 },
    });

    if (state.layout.isDesktop && state.layout.menuOpen) {
      dispatch({
        type: 'SET_MENU_OPEN',
        payload: { menuOpen: false },
      });
    }
  };

  const deleteCaches = async () => {
    try {
      const keys = await window.caches.keys();
      await Promise.all(keys.map((key) => caches.delete(key)));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    try {
      if ('serviceWorker' in navigator) {
        caches.keys().then(function (cacheNames) {
          cacheNames.forEach(function (cacheName) {
            caches.delete(cacheName);
          });
        });

        navigator.serviceWorker
          .getRegistrations()
          .then(function (registrations) {
            for (const registration of registrations) {
              registration.unregister();
            }
          })
          .catch(function (err) {
            console.log('Service Worker registration failed: ', err);
          });
      }

      if (!localStorage.getItem('cacheReset')) {
        deleteCaches()
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          .then((_) => {
            localStorage.setItem('cacheReset', 'yes');
          });
      }
    } catch (error) {
      console.log('error cache', error);
    }
  }, []);

  useEffect(() => {
    if (slugSuffix && !loadedSlugSuffix) {
      const tabSlide = allSlides.find((tab) => tab.slug === slugSuffix);

      if (Object.keys(state.layout.slugSuffixes)?.length && navRef?.slug) {
        dispatch({
          type: 'SET_STOP_HISTORY_CHANGE',
          payload: {
            stopHistoryChange: true,
          },
        });

        setTimeout(() => {
          const tabReference = productDetails.find((p) => p.id === navRef.slug);

          if (tabReference) {
            dispatch({
              type: 'SET_CURRENTLY_VISIBLE_NAV',
              payload: { tab: tabReference },
            });
            dispatch({
              type: 'SWIPE_TO_TAB',
              payload: {
                indexInfo: true,
                tab: tabSlide,
              },
            });
            if (!state.layout.isDesktop) {
              dispatch({
                type: 'SET_DETAIL_TAB',
                payload: {
                  isMobile: true,
                  selectedDetailTab: tabReference,
                },
              });
            }
          }

          setLoadedSlugSuffix(true);
        }, 500);

        if (state.layout.slugSuffixes[navRef?.slug]) {
          setTimeout(() => {
            state.layout.slugSuffixes[navRef?.slug](slugSuffix);
            dispatch({
              type: 'SET_STOP_HISTORY_CHANGE',
              payload: {
                stopHistoryChange: false,
              },
            });
            setLoadedSlugSuffix(true);
          }, 0);
        } else {
          dispatch({
            type: 'SET_STOP_HISTORY_CHANGE',
            payload: {
              stopHistoryChange: false,
            },
          });
        }
      }
      if (slugSuffix) {
        const versionSelected = dataVersions.find(
          (version) => version.slug === slugSuffix
        );

        if (versionSelected) {
          dispatch({
            type: 'SET_CURRENT_VERSION',
            payload: {
              currentVersion: versionSelected,
            },
          });
        }
      }
    }
  }, [
    navRef,
    state.layout.slugSuffixes,
    slugSuffix,
    loadedSlugSuffix,
    dispatch,
  ]);

  useEffect(() => {
    if (state && window?.location?.search) {
      const queryObject = window.location.search;
      if (queryObject) {
        dispatch({
          type: 'SET_QUERY_STRING',
          payload: { queryString: new URLSearchParams(queryObject).toString() },
        });
      }
    }
  }, []);

  useEffect(() => {
    if (navRef) {
      timeout.current = setTimeout(() => {
        dispatch({
          type: 'SCROLL_TO_REFERENCE',
          payload: {
            reference: navRef.reference ?? navRef,
          },
        });
      }, 400);
    }

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [dispatch, navRef, slugSuffix]);

  useEffect(() => {
    window?.addEventListener('resize', handleResize);
    handleResize();
    setLoaded(true);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (navRef) {
      timeout.current = setTimeout(() => {
        store.scrollToRef(navRef);
      }, 400);
    }

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [dispatch, navRef, store]);

  return (
    <ContainerBody includeNavigation>
      <Conditional notOn="mobile">
        <HeroDesktop2 />
      </Conditional>
      <Conditional notOn="desktop">
        <MainStories />
      </Conditional>
      <BannerSection />
      <Banner125Anos />
      <VersionsSectionV3 />
      {/* <BannerSectionEndurance /> */}
      {loaded && <GalleryV1 />}
      <ProductDetails />
      <DealSection />
      <Footer />
      <WhatsappBtn />
    </ContainerBody>
  );
};

export default Home;
