import React, { useState, useEffect, useContext, useRef } from "react";
import Image from "next/image";
import SwiperCore, { Navigation } from "swiper";
import { Swiper as SwiperProps } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import scssStyles from "@/utils/scssStyles";
import styles from "./version.module.scss";
import { Context } from "@/context";
import { PREFIX } from "@utils/imagePrefixes";
import formatString from "@utils/formatString";
import {
  dataMenuLabels,
  links,
  COMPRE_TITLE,
  MONTE_TITLE,
} from "@/data/menu.data";
import DataLayer from "@/utils/DataLayer";
import { NewCta } from "@/components/new-cta";
import { Chevron } from "@/components/chevron";
import getClientXFromDifferentEvents from "@/utils/getClientXFromDifferentEvents";
import SectionTitle from "@components/section-title";
import { Conditional } from "@/components";

SwiperCore.use([Navigation]);

let API_PRICE = `https://price-service.k8s-stage.fcalatam.com.br`;
API_PRICE = `https://price-service.k8s.fcalatam.com.br`;
// if (window.location.href.includes('argo.fiat.com.br')) {
// }

let API_MODELS = `https://mvs-service.k8s-stage.fcalatam.com.br`;
API_MODELS = `https://mvs-service.k8s.fcalatam.com.br`;
// if (window.location.href.includes('argo.fiat.com.br')) {
// }

let API_COLORS = `https://part-service.k8s-stage.fcalatam.com.br`;
API_COLORS = `https://part-service.k8s.fcalatam.com.br`;
// if (window.location.href.includes('argo.fiat.com.br')) {
// }

interface colors {
  colorResponseCode: string;
  colorName: string;
  colorNameFormatted: string;
  pathSrc: string;
  colorActive: boolean;
}

interface verifyColors {
  cor: {
    hash: string;
    nome: string;
  };
}

export interface Car {
  id: number;
  mvsCode: string;
  slug: string;
  model: {
    shortname: string;
    fullname: string;
    titleTag: string;
    altTag: string;
    subTltDetails: string;
  };
  description: string;
  detialsDescription: string;
  optionals: {
    text: string;
    altThumb: string;
    pathThumb: string;
  }[];
  colors: colors[];
  price: number;
  financingLinks: string;
  path: string;
  versionCtaButton: {
    id: string;
    label: string;
    url: string;
    backgroundColor: string;
    color: string;
    download?: boolean;
    excludeArrow?: boolean;
    iconCode?: string;
  }[];
}

interface CarInfoProps {
  car?: Car;
}

const versionsRef: {
  title: string;
  titleDetails: string;
  subTitleDetails: string;
  slug: string;
}[] = [
    {
      title: "Argo 1.0",
      titleDetails: "1.0 MANUAL",
      subTitleDetails: "ARGO",
      slug: "Argo 1.0",
    },
    {
      title: "Drive 1.0",
      titleDetails: " 1.0 MANUAL",
      subTitleDetails: " DRIVE",
      slug: "Drive 1.0",
    },
    {
      title: "Endurance 1.3",
      titleDetails: "1.3 MANUAL",
      subTitleDetails: "Endurance",
      slug: "Endurance 1.3",
    },
    {
      title: "Drive 1.3 AT",
      titleDetails: "1.3 AUTOMÁTICO",
      subTitleDetails: "DRIVE",
      slug: "Drive 1.3 at",
    },
    {
      title: "Trekking 1.3",
      titleDetails: "1.3 MANUAL",
      subTitleDetails: "TREKKING",
      slug: "Trekking 1.3",
    },
    {
      title: "Trekking 1.3 AT",
      titleDetails: "1.3 AUTOMÁTICO",
      subTitleDetails: "TREKKING",
      slug: "Trekking 1.3 at",
    },
  ];
const pageSubsection = "versoes";
const year_base = "2025";

export const cars: Car[] = [
  {
    id: 0,
    mvsCode: "358ACS1",
    slug: formatString(`${versionsRef[0].slug}`),
    model: {
      fullname: `${versionsRef[0].title}`,
      shortname: `${versionsRef[0].titleDetails}`,
      titleTag: "Fiat Argo versão 1.0",
      altTag: "Fiat Argo versão 1.0 na cor branca vista lateral e frontal.",
      subTltDetails: `${versionsRef[0].subTitleDetails}`,
    },
    description:
      "A combinação perfeita entre custo benefício com o máximo de estilo.",
    detialsDescription:
      "A combinação perfeita entre custo benefício com o máximo de estilo.",
    optionals: [
      {
        text: "Ar-condicionado",
        pathThumb: `${PREFIX}Versions/${versionsRef[0].title}/features/central-multimidia@3x.webp`,
        altThumb: "Ar-condicionado",
      },
      {
        text: "Direção elétrica",
        pathThumb: `${PREFIX}Versions/${versionsRef[0].title}/features/farois-full-led@3x.webp`,
        altThumb: "Direção elétrica",
      },
      {
        text: "Alarme com chave canivete",
        pathThumb: `${PREFIX}Versions/${versionsRef[0].title}/features/motor-t270-turbo-flex@3x.webp`,
        altThumb: "Alarme com chave canivete",
      },
    ],
    colors: [],
    price: 0,
    financingLinks: `https://argo.fiat.com.br/monte.html?mvs=358ACS1&year=${year_base}&openFundingPlans=true`,
    path: `${PREFIX}Versions/desktop/argo-1.0/249@3x.png`,
    versionCtaButton: [
      {
        id: "ctaButton-0",
        label: MONTE_TITLE,
        url: `${links.monte.replace(
          "#versao",
          ""
        )}?mvs=358ACS1&year=${year_base}#versao`,
        backgroundColor: "#EDEDE3",
        color: "#FC1430",
        excludeArrow: false,
      },
      {
        id: "ctaButton-1",
        label: COMPRE_TITLE,
        url: `${links.compre}?mvs=358ACS1&year=${year_base}#versao`,
        backgroundColor: "#EDEDE3",
        color: "#FC1430",
        excludeArrow: false,
      },
    ],
  },

  {
    id: 1,
    mvsCode: "358ATS1",
    slug: formatString(`${versionsRef[1].slug}`),
    model: {
      fullname: `${versionsRef[1].title}`,
      shortname: `${versionsRef[1].titleDetails}`,
      titleTag: "Fiat Argo versão Drive 1.3",
      altTag: "Personalidade, conforto e tecnologia andam juntos com o Argo!",
      subTltDetails: `${versionsRef[1].subTitleDetails}`,
    },
    description:
      "Mais potente e tecnológico, com o design que só a Fiat entrega!",
    detialsDescription:
      "Personalidade, conforto e tecnologia andam juntos com o Argo!",
    optionals: [
      {
        text: "Central Multimídia 7”",
        pathThumb: `${PREFIX}Versions/${versionsRef[1].title}/features/central-multimidia@3x.webp`,
        altThumb: "Central Multimídia 7”",
      },
      {
        text: "Comandos no volante",
        pathThumb: `${PREFIX}Versions/${versionsRef[1].title}/features/motor-270-turbo-flex@3x.webp`,
        altThumb: "Comandos no volante",
      },
      {
        text: "Sensor de pressão nos pneus",
        pathThumb: `${PREFIX}Versions/${versionsRef[1].title}/features/roda-de-18@3x.webp`,
        altThumb: "Sensor de pressão nos pneus",
      },
    ],
    colors: [],
    price: 0,
    financingLinks: `https://argo.fiat.com.br/monte.html?mvs=358ATS1&year=${year_base}&openFundingPlans=true`,
    path: `${PREFIX}Versions/desktop/drive-1.0/619@3x.png`,
    versionCtaButton: [
      {
        id: "ctaButton-0",
        label: MONTE_TITLE,
        url: `${links.monte.replace(
          "#versao",
          ""
        )}?mvs=358ATS1&year=${year_base}#versao`,
        backgroundColor: "#EDEDE3",
        color: "#FC1430",
        excludeArrow: false,
      },
      {
        id: "ctaButton-1",
        label: COMPRE_TITLE,
        url:  `${links.compre}?mvs=358ATS1&year=${year_base}#versao`,
        backgroundColor: "#EDEDE3",
        color: "#FC1430",
        excludeArrow: false,
      },
    ],
  },

  /////////
  // {
  //   id: 2,
  //   mvsCode: "358AAD1",
  //   slug: formatString(`${versionsRef[2].slug}`),
  //   model: {
  //     fullname: `${versionsRef[2].title}`,
  //     shortname: `${versionsRef[2].titleDetails}`,
  //     altTag: "Fiat Argo versão Endurance",
  //     titleTag:
  //       "Fiat Argo versão Trekking 1.3 Automático na cor vermelha vista lateral e frontal.",
  //     subTltDetails: `${versionsRef[2].subTitleDetails}`,
  //   },
  //   description:
  //     "Design esportivo e tecnologia de ponta para quem busca performance!",
  //   detialsDescription:
  //     "Feito para acompanhar o seu negócio em qualquer desafio.",
  //   optionals: [
  //     {
  //       text: "Suspensão elevada",
  //       pathThumb: `${PREFIX}Versions/${formatString(
  //         versionsRef[2].title
  //       )}/features/wireless-charger@3x.webp`,
  //       altThumb: "Suspensão elevada",
  //     },
  //     {
  //       text: "Motor 1.3 Firefly 107 cv",
  //       pathThumb: `${PREFIX}Versions/${formatString(
  //         versionsRef[2].title
  //       )}/features/motor-t270-turbo-flex@3x.webp`,
  //       altThumb: "Motor 1.3 Firefly 107 cv",
  //     },
  //     {
  //       text: "Pneus de uso misto",
  //       pathThumb: `${PREFIX}Versions/${formatString(
  //         versionsRef[2].title
  //       )}/features/design-escurecido@3x.webp`,
  //       altThumb: "Pneus de uso misto",
  //     },
  //     {
  //       text: "Protetor de Cárter",
  //       pathThumb: `${PREFIX}Versions/${formatString(
  //         versionsRef[2].title
  //       )}/features/design-escurecido@3x.webp`,
  //       altThumb: "Protetor de Cárter",
  //     },
  //   ],
  //   colors: [],
  //   price: 0,
  //   financingLinks: "https://argo.fiat.com.br/monte.html?openFundingPlans=true",
  //   path: `${PREFIX}Versions/desktop/endurance-1.3/249@3x.png`,
  //   versionCtaButton: [
  //     {
  //       id: "ctaButton-0",
  //       label: MONTE_TITLE,
  //       url: `${links.monte.replace(
  //         "#versao",
  //         ""
  //       )}?mvs=358AAD1&year=${year_base}#versao`,
  //       backgroundColor: "#EDEDE3",
  //       color: "#FC1430",
  //       excludeArrow: false,
  //     },
  //     {
  //       id: "ctaButton-1",
  //       label: COMPRE_TITLE,
  //       url: links.compre,
  //       backgroundColor: "#EDEDE3",
  //       color: "#FC1430",
  //       excludeArrow: false,
  //     },
  //   ],
  // },

  ///
  {
    id: 2,
    mvsCode: "358AFB1",
    slug: formatString(`${versionsRef[3].slug}`),
    model: {
      fullname: `${versionsRef[3].title}`,
      shortname: `${versionsRef[3].titleDetails}`,
      altTag: "Fiat Argo versão Drive 1.3 Automático",
      titleTag:
        "Fiat Argo versão Drive 1.3 Automático na cor preta vista lateral e frontal.",
      subTltDetails: `${versionsRef[3].subTitleDetails}`,
    },
    description:
      "O conforto e potência andam juntos e com muita personalidade!",
    detialsDescription:
      "Potência, economia e o conforto do câmbio CVT com um estilo que só a FIAT tem!",
    optionals: [
      {
        text: "Câmbio automático CVT",
        pathThumb: `${PREFIX}Versions/${formatString(
          versionsRef[3].title
        )}/features/wireless-charger@3x.webp`,
        altThumb: "Câmbio automático CVT",
      },
      {
        text: "Led Design ",
        pathThumb: `${PREFIX}Versions/${formatString(
          versionsRef[3].title
        )}/features/motor-t270-turbo-flex@3x.webp`,
        altThumb: "Led Design ",
      },
      {
        text: "Ar-condicionado Digital",
        pathThumb: `${PREFIX}Versions/${formatString(
          versionsRef[3].title
        )}/features/design-escurecido@3x.webp`,
        altThumb: "Ar-condicionado Digital",
      },
    ],
    colors: [],
    price: 0,
    financingLinks: `https://argo.fiat.com.br/monte.html?mvs=358AFB1&year=${year_base}&openFundingPlans=true`,
    path: `${PREFIX}Versions/desktop/drive-1.3-at/978@3x.png`,
    versionCtaButton: [
      {
        id: "ctaButton-0",
        label: MONTE_TITLE,
        url: `${links.monte.replace(
          "#versao",
          ""
        )}?mvs=358AFB1&year=${year_base}#versao`,
        backgroundColor: "#EDEDE3",
        color: "#FC1430",
        excludeArrow: false,
      },
      {
        id: "ctaButton-1",
        label: COMPRE_TITLE,
        url: `${links.compre}?mvs=358AFB1&year=${year_base}#versao`,
        backgroundColor: "#EDEDE3",
        color: "#FC1430",
        excludeArrow: false,
      },
    ],
  },
  // {
  //   id: 4,
  //   mvsCode: "358AGR1",
  //   slug: formatString(`${versionsRef[4].slug}`),
  //   model: {
  //     fullname: `${versionsRef[4].title}`,
  //     shortname: `${versionsRef[4].titleDetails}`,
  //     altTag: "Fiat Argo versão Trekking 1.3",
  //     titleTag:
  //       "Preparado para suas aventuras, o Argo ideal para os desbravadores!",
  //     subTltDetails: `${versionsRef[4].subTitleDetails}`,
  //   },
  //   description:
  //     "Tudo que você merece em um só carro: conforto, design, performance e tecnologia!",
  //   detialsDescription:
  //     "Preparado para suas aventuras, o Argo ideal para os desbravadores!",
  //   optionals: [
  //     {
  //       text: "Suspensão elevada",
  //       pathThumb: `${PREFIX}Versions/${formatString(
  //         versionsRef[4].title
  //       )}/features/wireless-charger@3x.webp`,
  //       altThumb: "Suspensão elevada",
  //     },
  //     {
  //       text: "Revestimento em couro ECO",
  //       pathThumb: `${PREFIX}Versions/${formatString(
  //         versionsRef[4].title
  //       )}/features/motor-t270-turbo-flex@3x.webp`,
  //       altThumb: "Revestimento em couro ECO",
  //     },
  //     {
  //       text: "Pneus de uso misto ATR",
  //       pathThumb: `${PREFIX}Versions/${formatString(
  //         versionsRef[4].title
  //       )}/features/design-escurecido@3x.webp`,
  //       altThumb: "Pneus de uso misto ATR",
  //     },
  //   ],
  //   colors: [],
  //   price: 0,
  //   financingLinks: "https://argo.fiat.com.br/monte.html?openFundingPlans=true",
  //   path: `${PREFIX}Versions/desktop/trekking-1.3/175@3x.png`,
  //   versionCtaButton: [
  //     {
  //       id: "ctaButton-0",
  //       label: MONTE_TITLE,
  //       url: `${links.monte.replace(
  //         "#versao",
  //         ""
  //       )}?mvs=358AGR1&year=${year_base}#versao`,
  //       backgroundColor: "#EDEDE3",
  //       color: "#FC1430",
  //       excludeArrow: false,
  //     },
  //     {
  //       id: "ctaButton-1",
  //       label: COMPRE_TITLE,
  //       url: links.compre,
  //       backgroundColor: "#EDEDE3",
  //       color: "#FC1430",
  //       excludeArrow: false,
  //     },
  //   ],
  // },
  {
    id: 3,
    mvsCode: "358AGB1",
    slug: formatString(`${versionsRef[5].slug}`),
    model: {
      fullname: `${versionsRef[5].title}`,
      shortname: `${versionsRef[5].titleDetails}`,
      altTag: "Fiat Argo versão Trekking 1.3 Automático",
      titleTag:
        "Fiat Argo versão Trekking 1.3 Automático na cor vermelha vista lateral e frontal.",
      subTltDetails: `${versionsRef[5].subTitleDetails}`,
    },
    description:
      "Design esportivo e tecnologia de ponta para quem busca performance!",
    detialsDescription:
      "Para os aventureiros que não abrem mão do conforto e da tecnologia!",
    optionals: [
      {
        text: " Keyless Entry N’ Go",
        pathThumb: `${PREFIX}Versions/${formatString(
          versionsRef[3].title
        )}/features/wireless-charger@3x.webp`,
        altThumb: " Keyless Entry N’ Go",
      },
      {
        text: "Ar-condicionado Digital",
        pathThumb: `${PREFIX}Versions/${formatString(
          versionsRef[3].title
        )}/features/motor-t270-turbo-flex@3x.webp`,
        altThumb: "Ar-condicionado Digital",
      },
      {
        text: "Piloto Automático",
        pathThumb: `${PREFIX}Versions/${formatString(
          versionsRef[3].title
        )}/features/design-escurecido@3x.webp`,
        altThumb: "Piloto Automático",
      },
    ],
    colors: [],
    price: 0,
    financingLinks: `https://argo.fiat.com.br/monte.html?mvs=358AGB1&year=${year_base}&openFundingPlans=true`,
    path: `${PREFIX}Versions/desktop/trekking-1.3-at/663@3x.png`,
    versionCtaButton: [
      {
        id: "ctaButton-0",
        label: MONTE_TITLE,
        url: `${links.monte.replace(
          "#versao",
          ""
        )}?mvs=358AGB1&year=${year_base}#versao`,
        backgroundColor: "#EDEDE3",
        color: "#FC1430",
        excludeArrow: false,
      },
      {
        id: "ctaButton-1",
        label: COMPRE_TITLE,
        url: `${links.compre}?mvs=358AGB1&year=${year_base}#versao`,
        backgroundColor: "#EDEDE3",
        color: "#FC1430",
        excludeArrow: false,
      },
    ],
  },
];

interface ColorButtonsProps {
  activeCarIndex: number;
  activeColorIndex?: number;
  setActiveColorIndex?: (colorIndex: number) => void;
}

// CINZA SILVESTONE COM TETO PRETO VULCANO
const handleReturnColorByVersion = (mvsCode: string) => {

  switch (mvsCode) {
    case "358ACG1":
      return "prata-bari";
    case "358AFA1":
      return "vermelho-montecarlo";
    case "358AGR1":
      return "cinza-silvestone-com-teto-preto-vulcano";
    case "358AGA1":
      return "vermelho-montecarlo-com-teto-preto-vulcano";
    case "358AAD1":
      return "branco-banchisa";
    default:
      return "branco-banchisa";
  }
};

const ColorChanger: React.FC<ColorButtonsProps> = ({
  activeCarIndex,
  activeColorIndex,
  setActiveColorIndex,
}) => {
  const { state, dispatch } = useContext(Context);
  const [corEncontrada, setCorEncontrada] = useState<string>("");

  const handleColorClick = (
    colorIndex: number,
    colorNameFormatted: string,
    colorName: string,
    colorCode: string
  ): void => {
    dispatch({
      type: "SET_COLOR_ACTIVE",
      payload: {
        colorActive: colorNameFormatted,
      },
    });


    const path = `${PREFIX}Versions/${state.layout.isDesktop ? "desktop" : "mobile"
      }/${cars[activeCarIndex].slug}/${colorCode}@3x.png`;

    cars[activeCarIndex].path = path;

    dispatch({
      type: "SET_VERSION_PHOTO_SRC",
      payload: {
        versionPhotoSrc: path,
      },
    });
  };

  useEffect(() => {
    if (!state.layout.colorActive) {
      dispatch({
        type: "SET_COLOR_ACTIVE",
        payload: {
          colorActive: handleReturnColorByVersion(
            state.layout.currentVersion.mvsCode!
          ),
        },
      });
    }
  }, [state.layout.colorActive, state.layout.currentVersion.slug]);

  const minhasCores: verifyColors[] = [
    { cor: { hash: "ffffff", nome: "branco-ambiente" } },
    { cor: { hash: "D64541", nome: "vermelho-tramonto" } },
    { cor: { hash: "333333", nome: "preto-carbon" } },
    { cor: { hash: "BCC6CC", nome: "prata-billet" } },
    { cor: { hash: "0B0B0B", nome: "preto-vulcano" } },
    { cor: { hash: "C40233", nome: "vermelho-montecarlo" } },
    { cor: { hash: "F0F0F0", nome: "branco-alaska" } },
    { cor: { hash: "FAFAFA", nome: "branco-banchisa" } },
    { cor: { hash: "FAFAFA", nome: "branco-banchisa" } },
    { cor: { hash: "98AFC7", nome: "prata-bari" } },
    { cor: { hash: "7D7D7D", nome: "cinza-silverstone" } },
    { cor: { nome: 'branco-banchisa-com-teto-preto-vulcano', hash: 'FAFAFA' } },
    { cor: { nome: 'vermelho-montecarlo-com-teto-preto-vulcano', hash: 'C40233' } },
    { cor: { nome: 'cinza-silvestone-com-teto-preto-vulcano', hash: '7D7D7D' } },
  ];

  function encontrarCorPorNome(
    nomeProcurado: string
  ): verifyColors | undefined {
    return minhasCores.find((cor) => cor.cor.nome === nomeProcurado);
  }

  return (
    <div className={styles.colorsContainer}>
      <p>Selecione a cor do seu Fiat Argo</p>
      <div className={styles.colors}>
        <nav>
          {cars[activeCarIndex].colors.map((color, i) => {
            const corEncontradaresp = encontrarCorPorNome(
              color.colorNameFormatted
            );
            // console.log("state.layout.colorActive", state.layout.colorActive);
            // console.log("color.colorNameFormatted", color.colorNameFormatted);
            const active =
              state.layout.colorActive === color.colorNameFormatted;
            return (
              <button
                className={active ? styles.active : " "}
                key={i}
                onClick={() => {
                  DataLayer.clickEvent({
                    element: color.colorName,
                    elementCategory: "icone",
                    pageSection: "versao",
                    pageSubsection: `argo-${state.layout.currentVersion.slug}`,
                  });
                  color.colorActive = true;
                  handleColorClick(
                    i,
                    color.colorNameFormatted,
                    color.colorName,
                    color.colorResponseCode
                  );
                }}
              >
                <span
                  className={styles.currCircleColor}
                  style={{
                    background: `radial-gradient(circle at 10px 14px, #${corEncontradaresp?.cor.hash ?? ""
                      } 9px, #${corEncontradaresp?.cor.hash ?? ""} 10px)`,
                  }}
                  title={color.colorName}
                />
                <span className={styles.currTextColor}>
                  <div className={styles.teste}></div>
                  <div className={styles.teste2}>{color.colorName}</div>
                </span>
              </button>
            );
          })}
        </nav>
      </div>
    </div>
  );
};

const CarInfo: React.FC<CarInfoProps> = ({ car }) => {
  const { state, dispatch } = useContext(Context);

  const availableColors = async (mvsCode: string) => {
    const dataColor = await fetch(
      `${API_COLORS}/v2/color?mvsCode=${mvsCode}&year=${year_base}&market=pt-BR`
    );
    const colorResponse = await dataColor.json();
    const dataPrice = await fetch(
      `${API_PRICE}/price?mvsCode=${mvsCode}&year=${year_base}&country=BR&state=SP&excludeParts=true`
    );
    const priceResponse = await dataPrice.json();

    cars.map((item) => {
      if (item.mvsCode === mvsCode) {
        colorResponse.map(
          (
            value: { colorResponseCode: string; name: string; code: string },
            i: number
          ) => {
            const path = `${PREFIX}Versions/${state.layout.isDesktop ? "desktop" : "mobile"
              }/${item.slug}/${value.code}@3x.png`;

            item.colors.push({
              colorName: value.name,
              colorResponseCode: value.code,
              colorNameFormatted: formatString(value.name),
              pathSrc: path,
              colorActive:
                formatString(value.name) ===
                handleReturnColorByVersion(mvsCode),
            });
          }
        );
        item.price = priceResponse.price.toLocaleString("pt-br", {
          minimumFractionDigits: 2,
        });
      }
    });

    dispatch({
      type: "SET_REQUEST_FINISHED",
      payload: { requestFinished: true },
    });

    return dataColor;
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch(
        `${API_MODELS}/mvs?brand=FIAT&model=358&year=${year_base}&market=pt-BR&versionsShown=&excludeGrouped=true`
      );
      const json = await data.json();
      json.map((item: { code: string }) => availableColors(item.code));
    };

    fetchData().catch(console.error);
  }, []);

  return (
    <div
      key={car?.id}
      className={scssStyles([styles.containerCarInfo, styles.info])}
    >
      {!state.layout.isDesktop && state.layout.requestFinished && (
        <ColorChanger activeCarIndex={car?.id ? car.id : 0} />
      )}
      <div className={styles.optional}>
        <div className={styles.title}>
          <h3 className={styles.fullname}>
            <span>{car?.model.shortname}</span>
            <strong>{car?.model.subTltDetails}</strong>
          </h3>
          <div className={styles.line} />

          <p className={styles.description}>{car?.detialsDescription}</p>
        </div>
        <ul className={styles.thumbs}>
          {car?.optionals.map((optional, index) => (
            <li key={index}>
              <div className={styles.textHolder}>
                <div className={styles.lineDescription} />
                <p>{optional.text}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
      {state.layout.isDesktop && state.layout.requestFinished && (
        <ColorChanger activeCarIndex={car?.id ? car.id : 0} />
      )}
      <div className={styles.price}>
        {state.layout.requestFinished && (
          <div className={styles.payment}>
            <div>
              <p>A partir de</p>
              <p>
                <strong>R$ {car?.price ? car.price : "- - "}</strong>
              </p>
            </div>
              <a
                target="_blank"
                href={car?.financingLinks}
                className={styles.pricevalue}
                onClick={() => {
                  DataLayer.clickEvent({
                    element: "Simular as parcelas",
                    elementCategory: "botao",
                    pageSection: "conteudo",
                    pageSubsection: "versoes",
                  });
                }}
              >
                Simular as parcelas
              </a>
          </div>
        )}

        <div className={styles.ctas}>
          <div className={styles.ctaLinks}>
            {car?.versionCtaButton.map((item, index) => {
              const layerEvent = () => {
                DataLayer.clickEvent({
                  elementCategory: "cta",
                  element: item.label,
                  pageSection: "versao",
                  pageSubsection: `argo-${state.layout.currentVersion.slug}`,
                });
              };
              return (
                <a
                  target="_blank"
                  href={item.url}
                  key={`version-cta${item.id}-${index}`}
                >
                  <NewCta
                    width={state.layout.isDesktop ? "172px" : "100%"}
                    label={item.label}
                    secundary={index === 1}
                    handleClick={() => layerEvent()}
                  />
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const Carousel: React.FC = () => {
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore | null>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [navController, setNavController] = useState<SwiperProps>();
  //const [currCar, setCurrCar] = useState("");
  const { state, dispatch } = useContext(Context);
  const reference = dataMenuLabels().find((c) => c.slug === "versoes");
  const [initialIndex, setInitialIndex] = useState<any>(0);
  const [touchLocation, setTouchLocation] = useState<PointerEvent["clientX"]>();

  useEffect(() => {
    setTimeout(() => {
      const index = cars.findIndex(
        (c) => c.slug === state.layout.currentVersion.slug
      );
      if (index) {
        setInitialIndex(index);
        swiperInstance?.slideTo(index);
      } else {
        setInitialIndex(1);
      }
    }, 100);

    if (state.layout.galleryExpanded) {
      swiperInstance?.keyboard.disable();
    } else {
      swiperInstance?.keyboard.enable();
    }
  }, [
    initialIndex,
    activeIndex,
    dispatch,
    state.layout.currentVersion,
    state.layout.galleryExpanded,
    swiperInstance,
    swiperInstance?.keyboard,
  ]);

  const handleButtonClick = (index: number) => {
    swiperInstance?.slideTo(index);
    setActiveIndex(index);
  };

  const handleNavClick = (index: number) => {
    dispatch({
      type: "SET_VERSION_PHOTO_SRC",
      payload: { versionPhotoSrc: cars[index]?.path },
    });
  };

  useEffect(() => {
    const activeColor = cars[activeIndex].colors.filter(
      (value) => value.colorActive
    );
    dispatch({
      type: "SET_COLOR_ACTIVE",
      payload: {
        colorActive: activeColor[0]?.colorNameFormatted,
      },
    });

    const photo =
      window?.innerWidth < 1024
        ? cars[activeIndex]?.path.replaceAll("desktop", "mobile")
        : cars[activeIndex]?.path;

    dispatch({
      type: "SET_VERSION_PHOTO_SRC",
      payload: {
        versionPhotoSrc: photo,
      },
    });
  }, [activeIndex]);

  return (
    <section className={styles.versions}>
      <div className={styles.container}>
        <div className={styles.title}>
          {state.layout.isDesktop ? (
            <>
              <SectionTitle title={"CONHEÇA"} strong={"TODAS AS VERSÕES"} />
            </>
          ) : (
            <h2 className={styles.mobileTitle}>
              <span className={styles.discovery}>CONHEÇA</span>{" "}
              <strong>
                TODAS
                <br />
                AS VERSÕES
              </strong>
            </h2>
          )}
        </div>

        <nav className={styles.navigation}>
          <Swiper
            onSwiper={(s) => setNavController(s)}
            controller={navController ? { control: navController } : undefined}
            allowTouchMove={false}
            slidesOffsetBefore={28}
            slidesOffsetAfter={16}
            slidesPerView={'auto'}
            spaceBetween={8}
            centeredSlidesBounds={!state.layout.isDesktop}
            centeredSlides={!state.layout.isDesktop}
            className={styles.navswiper}
          >
            {cars.map((car, index) => {
              return (
                <SwiperSlide
                  className={scssStyles([
                    styles.navslide,
                    activeIndex === index ? styles.navslide_active : "",
                  ])}
                  key={`car-navigattion-item-${index}`}
                >
                  <span
                    onClick={() => {
                      setActiveIndex(index);
                      DataLayer.clickEvent({
                        element: car.model.fullname,
                        elementCategory: "botao",
                        pageSection: "conteudo",
                        pageSubsection,
                      });
                      handleButtonClick(index);
                    }}
                    className={scssStyles([styles.navname])}
                  >
                    <span
                      style={{
                        fontWeight: 700,
                      }}
                    >
                      {car.model.fullname}
                    </span>
                  </span>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </nav>
      </div>

      <div className={styles.containerSwipper}>
        <Swiper
          loop={false}
          onSwiper={setSwiperInstance}
          onSlideChange={(swiper) => {
            navController?.slideTo(swiper.activeIndex);
            const selectedVersion = cars[swiper.activeIndex];
            dispatch({
              type: "SET_CURRENT_VERSION",
              payload: {
                currentVersion: selectedVersion,
              },
            });
            dispatch({
              type: "SET_CURRENTLY_VISIBLE_NAV",
              payload: {
                view: reference,
                suffix: selectedVersion.slug,
              },
            });
            setActiveIndex(swiper.activeIndex);
          }}
          pagination={false}
          spaceBetween={20}
          slidesPerView={1}
          centeredSlides={true}
          initialSlide={initialIndex}
          onTouchStart={(_, event) => {
            const clientX = getClientXFromDifferentEvents(event);
            setTouchLocation(clientX);
          }}
          onTouchEnd={(_, event) => {
            const clientX = getClientXFromDifferentEvents(event);
            DataLayer.swipeEvent({
              element: (touchLocation ?? 0) > clientX ? "proximo" : "anterior",
              elementCategory: "slide",
              pageSection: "conteudo",
              pageSubsection: "ver",
            });
          }}
        >
          {state.layout.requestFinished &&
            cars.map((car, i) => {
              return (
                <SwiperSlide key={car.id}>
                  <div className={styles.carousel}>
                    <Image
                      className={styles.images}
                      height={480}
                      width={775}
                      layout="intrinsic"
                      src={
                        activeIndex === i
                          ? state.layout.versionPhotoSrc
                          : car.path
                      }
                      alt={` ${car.model.altTag}`}
                      title={`${car.model.titleTag}`}
                      aria-label={`${car.model.altTag}`}
                      priority={activeIndex === i}
                      objectFit="cover"
                    />
                    <Conditional
                      desktop={
                        <div
                          style={{
                            position: "absolute",
                            right: 0,
                            bottom: 0,
                            zIndex: -1,
                          }}
                        >
                          <svg
                            width="669.188"
                            height="391.214"
                            viewBox="0 0 669.188 391.214"
                          >
                            <defs>
                              <linearGradient
                                id="linear-gradient"
                                y1="0.445"
                                x2="0.26"
                                y2="0.662"
                                gradientUnits="objectBoundingBox"
                              >
                                <stop offset="0" stopColor="#ff1430" />
                                <stop offset="1" stopColor="#3b369e" />
                              </linearGradient>
                              <linearGradient
                                id="linear-gradient-2"
                                x1="-0.049"
                                y1="0.474"
                                x2="0.923"
                                y2="0.451"
                                gradientUnits="objectBoundingBox"
                              >
                                <stop offset="0" stopColor="#ffb80f" />
                                <stop offset="1" stopColor="#ff1430" />
                              </linearGradient>
                            </defs>
                            <path
                              id="União_22"
                              data-name="União 22"
                              d="M0,390.183,126.6,0H643.355l0,.011h24.636l-.011,390.167-151.221,0v0Z"
                              transform="translate(0.688 0.518)"
                              stroke="rgba(0,0,0,0)"
                              strokeMiterlimit="10"
                              strokeWidth="1"
                              fill="url(#linear-gradient)"
                            />
                            <path
                              id="União_14"
                              data-name="União 14"
                              d="M0,390.2,126.6.018,611.545,0h.745l.024,390.2H612.3v.01Z"
                              transform="translate(56.374 0.5)"
                              stroke="rgba(0,0,0,0)"
                              strokeMiterlimit="10"
                              strokeWidth="1"
                              fill="url(#linear-gradient-2)"
                            />
                          </svg>
                        </div>
                      }
                    ></Conditional>
                  </div>
                </SwiperSlide>
              );
            })}

          <Chevron
            className={styles.btnPrev}
            handleClick={() => {
              DataLayer.clickEvent({
                element: "anterior",
                elementCategory: "icone",
                pageSection: "conteudo",
                pageSubsection,
              });
              swiperInstance?.slidePrev();
            }}
            chevronColor={"#000"}
          />

          <Chevron
            className={styles.btnNext}
            handleClick={() => {
              DataLayer.clickEvent({
                element: "proximo",
                elementCategory: "icone",
                pageSection: "conteudo",
                pageSubsection,
              });
              swiperInstance?.slideNext();
            }}
            chevronColor={"#000"}
          />
        </Swiper>
      </div>

      <CarInfo car={cars[activeIndex]} />
    </section>
  );
};

export default Carousel;
